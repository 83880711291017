import { StepSchemas, VariableSchemas } from "@ea/shared_types/next/ea.schema";
import { z } from "zod";
import { createFieldNameGetter } from "../../utils";

export type CreateStepFormValues = z.infer<typeof StepSchemas.createData>;

export type CreateVariableFormValues = z.infer<typeof VariableSchemas.create>;

export const ExistingVariableForm = VariableSchemas["general"].pick({ id: true });

export type VariableAssignmentFormData = CreateStepFormValues & {
  value: { code: { assignedVariableId: number | string } };
};

export const fieldName = createFieldNameGetter<VariableAssignmentFormData>();
